import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import './Texmex.css';
import './Popup.css';
import './DialogModel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';

const Texmex = () => {
    const [texmexItems, setTexmexItems] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [modalItem, setModalItem] = useState(null); // State for modal
    const [popupItem, setPopupItem] = useState(null); // State for item popup
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchTexmexItems = async () => {
            try {
                const response = await fetch(`${BASE_URL}/menu-items`);
                const data = await response.json();
                // Filter to show only "TEX MEX" category items
                const filteredData = data.filter(item => item.category === "TEX MEX");
                setTexmexItems(filteredData);
            } catch (error) {
                console.error('Error fetching TEX MEX items:', error);
            }
        };

        fetchTexmexItems();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: <div className="slick-next">→</div>,
        prevArrow: <div className="slick-prev">←</div>,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const handleBuyNow = (item) => {
        if (item.uber_link && item.deliveroo_link) {
            setModalItem(item);
        } else {
            checkAppAndOpenLink(item.uber_link || item.deliveroo_link);
        }
    };

    const checkAppAndOpenLink = (url) => {
        // Check if the app is installed by attempting to open the URL
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const closeModal = () => {
        setModalItem(null); // Close the modal
    };

    const handleItemClick = (item) => {
        setPopupItem(item); // Set the clicked item to show in the popup
    };

    const closePopup = () => {
        setPopupItem(null); // Close the item popup
    };

    const handleSearchIconClick = () => {
        if (isSearchOpen) {
            setSearchQuery(''); // Clear the search input when closing
        }
        setIsSearchOpen(!isSearchOpen);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredItems = texmexItems.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [sortOption, setSortOption] = useState('name'); // State for sorting option

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const sortedItems = [...filteredItems].sort((a, b) => {
        if (sortOption === 'name') {
            return a.name.localeCompare(b.name);
        } else if (sortOption === 'date') {
            return new Date(b.date) - new Date(a.date);
        }
        return 0;
    });

    return (
        <section id="texmex-section" className="texmex">
            <div className="texmex-header">
                <div className="sort-items">
                    <select value={sortOption} onChange={handleSortChange}>
                        <option value="name">Name</option>
                        <option value="date">Date</option>
                    </select>
                </div>
                <h2 className="texmex-title">Tex Mex</h2>
                <div className="search-wrapper">
                    {isSearchOpen && (
                        <div className="search-container">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <button className="cancel-search" onClick={handleSearchIconClick}>×</button>
                        </div>
                    )}
                    {!isSearchOpen && (
                        <i className="fas fa-search search-icon" onClick={handleSearchIconClick}></i>
                    )}
                </div>
            </div>
            
            {showAll ? (
                <div className="texmex-grid">
                    {sortedItems.map((item, index) => (
                        <div className="texmex-card-small" key={index} data-aos="flip-left" data-aos-delay={index * 100} onClick={() => handleItemClick(item)}>
                            <div className="texmex-image-small">
                                <img src={item.image} alt={item.name} />
                            </div>
                            <div className="texmex-content-small">
                                <h3>{item.name}</h3>
                                <p>{item.description}</p>
                                <span className="texmex-price-small">${item.price}</span>
                                <button className="texmex-buy-button-small" onClick={(e) => { e.stopPropagation(); handleBuyNow(item); }}>Buy Now</button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <Slider {...settings}>
                        {sortedItems.slice(0, 3).map((item, index) => (
                            <div className="texmex-card" key={index} data-aos="flip-left" data-aos-delay={index * 100} onClick={() => handleItemClick(item)}>
                                <div className="texmex-image">
                                    <img src={item.image} alt={item.name} />
                                </div>
                                <div className="texmex-content">
                                    <h3>{item.name}</h3>
                                    <p>{item.description}</p>
                                    <span className="texmex-price">€{item.price}</span>
                                    <button className="texmex-buy-button" onClick={(e) => { e.stopPropagation(); handleBuyNow(item); }}>Buy Now</button>
                                </div>
                            </div>
                        ))}
                    </Slider>   
                </>
                
            )}
            <button className="texmex-more-button" onClick={handleShowMore}>
                {showAll ? 'Show Less' : 'Show More'}
            </button>

            {/* Modal for delivery options */}
            {modalItem && (
                <div className="modal-dialog">
                    <div className="modal-content">
                        <span className="model-close" onClick={closeModal}>&times;</span>
                        <h2>Choose a delivery service</h2>
                        <button className="modal-button" onClick={() => checkAppAndOpenLink(modalItem.uber_link)}>
                            <i className="fab fa-uber"></i> Uber Eats
                        </button>
                        <button className="modal-button" onClick={() => checkAppAndOpenLink(modalItem.deliveroo_link)}>
                            <i className="fas fa-shipping-fast"></i> Deliveroo
                        </button>
                    </div>
                </div>
            )}

            {/* Popup for item details */}
            {popupItem && (
                <div className="popup-tab">
                    <div className="popup-content">
                        <div className="popup-image">
                            <img src={popupItem.image} alt={popupItem.name} />
                        </div>
                        <div className="popup-title">
                            <button className="popup-buy-button" onClick={() => handleBuyNow(popupItem)}>Buy Now</button>
                            <h3>{popupItem.name}</h3>
                        </div>
                        <span className="popup-price">€{popupItem.price}</span>
                        <span className="popup-close" onClick={closePopup}>&times;</span>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Texmex;