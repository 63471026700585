import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // Ensure correct import of i18n
import './Header.css';

const Header = () => {
    const { t } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(newLang);
    };


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
            <div className="container">
                <div className="logo">
                    <img src={`${process.env.PUBLIC_URL}/icon.svg`} alt="Logo Icon" className="logo-icon" />
                    <h1>{t('THENICETIME')}</h1>
                </div>
                <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
                    <ul>
                        <li><Link to="hero-section" smooth={true} duration={500}>{t('Home')}</Link></li>
                        <li><Link to="texmex-section" smooth={true} duration={500}>{t('Texmex')}</Link></li>
                        <li><Link to="menu-section" smooth={true} duration={500}>{t('Menu')}</Link></li>
                        <li><Link to="footer-section" smooth={true} duration={500}>{t('Contact')}</Link></li>
                    </ul>
                </nav>
                <div className="header-actions">
                    <button className="lang-toggle" onClick={toggleLanguage}>
                        {i18n.language === 'en' ? 'Français' : 'English'}
                    </button>
                    <div className="menu-toggle" onClick={toggleMenu}>
                        <span className="bar"></span>
                        <span className="bar"></span>
                        <span className="bar"></span>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;