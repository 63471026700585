import React, { useState, useEffect } from 'react';
import AdminDashboard from './components/AdminDashboard';
import Login from './components/Login';
import Home from './components/Home';
import Header from './components/Header';
import { uploadPhoto } from './api';
import './App.css';
import ReactGA from "react-ga4";

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';
const TRACKING_ID = "G-Z1W2WKBL66";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [menuItems, setMenuItems] = useState([]);

    ReactGA.initialize(TRACKING_ID);
    ReactGA.send("pageview");

    useEffect(() => {
        fetchMenuItems();
    }, []);

    const fetchMenuItems = async () => {
        try {
            const response = await fetch(`${BASE_URL}/menu-items`);
            const data = await response.json();
            setMenuItems(data);
        } catch (error) {
            console.error('Error fetching menu items:', error);
        }
    };

    const handleLogin = (status) => {
        setIsAuthenticated(status);
        setShowLogin(!status);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
    };

    const handleCancel = () => {
        setShowLogin(false);
    };

    const addItem = async (item) => {
        try {
            const response = await fetch(`${BASE_URL}/menu-items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
            });
            const newItem = await response.json();
            setMenuItems([...menuItems, newItem]);
        } catch (error) {
            console.error('Error adding item:', error);
        }
    };

    const updateItem = async (id, updatedItem) => {
        try {
            const response = await fetch(`${BASE_URL}/menu-items/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedItem),
            });
            const updated = await response.json();
            setMenuItems(menuItems.map(item => (item.item_id === id ? updated : item)));
        } catch (error) {
            console.error('Error updating item:', error);
        }
    };

    const deleteItem = async (id) => {
        try {
            await fetch(`${BASE_URL}/menu-items/${id}`, {
                method: 'DELETE',
            });
            setMenuItems(menuItems.filter(item => item.item_id !== id));
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const handleUploadPhoto = async (photo, item_id) => {
        try {
            const photoUrl = await uploadPhoto(photo, item_id);
            return photoUrl;
        } catch (error) {
            console.error('Error uploading photo:', error);
            return null;
        }
    };

    return (
        <div className="App">
            {!isAuthenticated && <Header />}
            {showLogin && <Login onLogin={handleLogin} onCancel={handleCancel} />}
            {isAuthenticated ? (
                <AdminDashboard
                    onLogout={handleLogout}
                    menuItems={menuItems}
                    addItem={addItem}
                    updateItem={updateItem}
                    deleteItem={deleteItem}
                    fetchMenuItems={fetchMenuItems}
                    handleUploadPhoto={handleUploadPhoto}
                />
            ) : (
                <>
                    <Home menuItems={menuItems} />
                 
                </>
            )}
            {!isAuthenticated && (
                <button className="admin-icon" onClick={() => setShowLogin(true)}>
                    <i className="fas fa-user-shield"></i> 
                </button>
            )}
        </div>
    );
}

export default App;