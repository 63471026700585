import axios from 'axios';

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';

export const uploadPhoto = async (photo, item_id) => {
    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('item_id', item_id);
    const response = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response.data.url; // Return the filename
};

export const deletePhoto = async (url) => {
    await axios.delete(`${BASE_URL}/delete-photo`, { data: { url } });
};