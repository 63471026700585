import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "Our Menu": "Our Menu",
            "ALL": "All",
            
            "SANDWISH SPECIAL": "Special Sandwich",
            "SANDICH": "Sandwich",
            "BURGERS": "Burgers",
            "ASSITTES": "Plates",
            "PANINI": "Panini",
            "SALADES": "Salads",
            "KIDSMENU": "Kids Menus",
            "DESSERTS": "Desserts",
            "BOISSONS": "Drinks",
            "Home": "Home",
            "Welcome to": "Welcome to",
            "Discover the taste of excellence": "Discover the taste of excellence",
            "Explore Menu": "Explore Menu",
            "Scroll Down": "Scroll Down",
            "Contact Us": "Contact Us",
            "Opening Hours": "Opening Hours",
            "Monday to Friday": "Monday to Friday",
            "Saturday to Sunday": "Saturday to Sunday",
            "Follow Us": "Follow Us",
            "All rights reserved": "All rights reserved"
        }
    },
    fr: {
        translation: {
            "Our Menu": "Notre Menu",
            "ALL": "Tous",
            "SANDWISH SPECIAL": "Sandwich Spécial",
            "SANDICH": "Sandwich",
            "BURGERS": "Burgers",
            "ASSITTES": "Assiettes",
            "PANINI": "Panini",
            "SALADES": "Salades",
            "KIDSMENU": "Menu Enfants",
            "DESSERTS": "Desserts",
            "BOISSONS": "Boissons",
            "Item Name": "Nom de l'article",
            "Item Price": "Prix de l'article",
            "Item Image URL": "URL de l'image de l'article",
            "Item Description": "Description de l'article",
            "Home": "Accueil",
            "Welcome to": "Bienvenue à",
            "Discover the taste of excellence": "Découvrez le goût de l'excellence",
            "Explore Menu": "Explorer le menu",
            "Scroll Down": "Faites défiler vers le bas",
            "Contact Us": "Contactez-nous",
            "Opening Hours": "Heures d'ouverture",
            "Monday to Friday": "Lundi à Vendredi",
            "Saturday to Sunday": "Samedi à Dimanche",
            "Follow Us": "Suivez-nous",
            "All rights reserved": "Tous droits réservés"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'en', // default language
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;