import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Menu.css';
import './Popup.css';
import './DialogModel.css';

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';

const Menu = () => {
    const { t } = useTranslation();
    const categories = [
        "ALL",
        "TACOS",
        "SANDWISH SPECIAL",
        "SANDICH",
        "BURGERS",
        "ASSITTES",
        "PANINI",
        "KIDSMENU",
        "SALADES",
        "DESSERTS",
        "BOISSONS"
    ];

    const [menuItems, setMenuItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('ALL');
    const [showAll, setShowAll] = useState(false);
    const [modalItem, setModalItem] = useState(null);
    const [popupItem, setPopupItem] = useState(null);
    const [isSearchOpen, setIsSearchOpen] = useState(false);
        const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        const fetchMenuItems = async () => {
            try {
                const response = await fetch(`${BASE_URL}/menu-items`);
                const data = await response.json();
                // Filter out "TEX MEX" category items
                const filteredData = data.filter(item => item.category !== "TEX MEX");
                setMenuItems(filteredData);
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };

        fetchMenuItems();
    }, []);

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
    };

    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <i key={i} className={`fa-star ${i < rating ? 'fas' : 'far'}`}></i>
            );
        }
        return stars;
    };

    const handleShowMore = () => {
        setShowAll(!showAll);
    };

    const handleBuyNow = (item) => {
        if (item.uber_link && item.deliveroo_link) {
            setModalItem(item);
        } else {
           checkAppAndOpenLink(item.uber_link || item.deliveroo_link);
        }
    };

    const checkAppAndOpenLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const closeModal = () => {
        setModalItem(null);
    };

    const handleItemClick = (item) => {
        setPopupItem(item);
    };

    const closePopup = () => {
        setPopupItem(null);
    };

    const handleSearchIconClick = () => {
        if (isSearchOpen) {
            setSearchQuery(''); // Clear the search input when closing
        }
        setIsSearchOpen(!isSearchOpen);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    // Check if a category has any items
    const hasItemsInCategory = (category) => {
        if (category === 'ALL') {
            return true;
        }
        return menuItems.some(item => item.category === category);

    };

    const filteredItems = menuItems.filter(item =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const [sortOption, setSortOption] = useState('name'); // State for sorting option
    
        const handleSortChange = (e) => {
            setSortOption(e.target.value);
        };
    
        const sortedItems = [...filteredItems].sort((a, b) => {
            if (sortOption === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortOption === 'date') {
                return new Date(b.date) - new Date(a.date);
            }
            return 0;
        });


    return (
        <section className="menu-section">
            <div className="menu-header">
            <div className="sort-items">
                    <select value={sortOption} onChange={handleSortChange}>
                        <option value="name">Name</option>
                        <option value="date">Date</option>
                    </select>
                </div>
            <h2 className="menu-title">{t('Our Menu')}</h2>
            <div className="search-wrapper">
                    {isSearchOpen && (
                        <div className="search-container">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <button className="cancel-search" onClick={handleSearchIconClick}>×</button>
                        </div>
                    )}
                    {!isSearchOpen && (
                        <i className="fas fa-search search-icon" onClick={handleSearchIconClick}></i>
                    )}
                </div>
            </div>
            <div className="menu-categories">
                {categories.map((category, index) => (
                    // Only render the category button if it has items
                    hasItemsInCategory(category) && (
                        <button
                            key={index}
                            className={`category-button ${selectedCategory === category ? 'selected' : ''}`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            {t(category)}
                        </button>
                    )
                ))}
            </div>
            <div className="menu-list">
                {sortedItems
                    .filter(item => selectedCategory === 'ALL' || item.category === selectedCategory)
                    .slice(0, showAll ? menuItems.length : 6)
                    .map((item, index) => (
                        <div className="menu-card" key={index} data-aos="fade-up" onClick={() => handleItemClick(item)}>
                            <div className="menu-image">
                                <img src={item.image} alt={item.name} className="menu-image" />
                                <div className="details-menu">
                                    {renderStars(item.rating)}
                                </div>
                            </div>
                            <div className="item-info-menu">
                                <h3>{item.name}</h3>
                                <p className="description-menu">{item.description}</p>
                            </div>
                            <div className="price-menu">€{item.price}</div>
                            <button className="buy-button-menu" onClick={(e) => { e.stopPropagation(); handleBuyNow(item); }}>{t('Buy Now')}</button>
                        </div>
                    ))}
            </div>
            {sortedItems.length > 6 && (
                <button className="menu-more-button" onClick={handleShowMore}>
                    {showAll ? t('Show Less') : t('Show More')}
                </button>
            )}
    
            {modalItem && (
                <div className="modal-dialog">
                    <div className="modal-content">
                        <span className="model-close" onClick={closeModal}>&times;</span>
                        <h2>{t('Choose a delivery service')}</h2>
                        <button className="modal-button" onClick={() => checkAppAndOpenLink(modalItem.uber_link)}>
                            <i className="fab fa-uber"></i> Uber Eats
                        </button>
                        <button className="modal-button" onClick={() => checkAppAndOpenLink(modalItem.deliveroo_link)}>
                            <i className="fas fa-shipping-fast"></i> Deliveroo
                        </button>
                    </div>
                </div>
            )}

            {popupItem && (
                <div className="popup-tab">
                    <div className="popup-content">
                        <div className="popup-image">
                            <img src={popupItem.image} alt={popupItem.name} />
                        </div>
                        <span className="popup-close" onClick={closePopup}>&times;</span>
                        <div className="popup-title">
                            <button className="popup-buy-button" onClick={() => handleBuyNow(popupItem)}>{t('Buy Now')}</button>
                            <h3>{popupItem.name}</h3>  
                        </div>
                        <span className="popup-price">€{popupItem.price}</span>
                    </div>
                </div>
            )}
        </section>
    );
};

export default Menu;