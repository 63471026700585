import React, { useState, useCallback } from 'react';
import './AdminDashboard.css';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from './cropImage'; // Utility function to get the cropped image
import { uploadPhoto, deletePhoto } from '../api'; // Ensure correct import of uploadPhoto and deletePhoto

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';

const AdminDashboard = ({ onLogout, menuItems, addItem, updateItem, deleteItem, fetchMenuItems }) => {
    const [newItem, setNewItem] = useState({ name: '', price: '', image: '', category: '', description: '', rating: '', uber_link: '', deliveroo_link: '' });
    const [editIndex, setEditIndex] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isCropping, setIsCropping] = useState(false);
    const [fileSize, setFileSize] = useState(100); // File size in KB

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewItem({ ...newItem, [name]: value });
    };

    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
                setIsCropping(true); // Show crop options
            };
            reader.readAsDataURL(file);
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(previewUrl, croppedAreaPixels, fileSize);
            setCroppedImage(croppedImage);
            setIsCropping(false); // Hide crop options
        } catch (e) {
            console.error('Error cropping image:', e);
        }
    }, [croppedAreaPixels, previewUrl, fileSize]);

    const handleFileSizeChange = (e) => {
        setFileSize(e.target.value);
    };

    const handleAddItem = async () => {
        const item_id = newItem.item_id || Math.floor(Math.random() * 1000);
        if (!newItem.image && croppedImage) {
            try {
                const photoFilename = await uploadPhoto(croppedImage, item_id);
                if (!photoFilename) {
                    throw new Error('Photo upload failed');
                }
                newItem.image = `${BASE_URL}${photoFilename}`;
            } catch (error) {
                console.error('Error uploading photo:', error);
                return;
            }
        }

        if (!newItem.image) {
            console.error('Image is required');
            return;
        }

        newItem.item_id = item_id; // Ensure item_id is set in newItem

        try {
            if (editIndex !== null) {
                await updateItem(menuItems[editIndex].item_id, newItem);
                setEditIndex(null);
            } else {
                await addItem(newItem);
            }
            setNewItem({ name: '', price: '', image: '', category: '', description: '', rating: '', uber_link: '', deliveroo_link: '' });
            setPreviewUrl(null);
            setCroppedImage(null);
            fetchMenuItems(); // Refresh the menu items
        } catch (error) {
            console.error('Error adding/updating item:', error);
        }
    };

    const handleEditItem = (index) => {
        setNewItem(menuItems[index]);
        setEditIndex(index);
    };

    const handleDeleteItem = async (index) => {
        const item = menuItems[index];
        await deleteItem(item.item_id);
        if (item.image) {
            const imageUrl = item.image.replace(`${BASE_URL}/uploadsnewnicetime/`, '');
            await deletePhoto(imageUrl); // Delete the photo from the server
        }
        fetchMenuItems(); // Refresh the menu items
    };

    return (
        <div className="admin-dashboard">
            <div className="top-buttons">
                <button className="choose-file-button">
                    <input type="file" name="photo" onChange={handlePhotoChange} />
                </button>
                <button className="logout-button" onClick={onLogout}>Logout</button>
            </div>
            <h1>Admin Dashboard</h1>
            {croppedImage && (
                <div className="preview-container">
                    <img className="preview-image" src={URL.createObjectURL(croppedImage)} alt="Cropped" />
                </div>
            )}
            {isCropping && (
                <div className="crop-container active">
                    <Cropper
                        image={previewUrl}
                        crop={crop}
                        zoom={zoom}
                        aspect={4 / 3}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={onCropComplete}
                    />
                    <button className="crop-button" onClick={showCroppedImage}>Crop Image</button>
                    <div className="file-size-control">
                        <label>File Size: {fileSize} KB</label>
                        <input
                            type="range"
                            min="10"
                            max="500"
                            value={fileSize}
                            onChange={handleFileSizeChange}
                        />
                    </div>
                </div>
            )}
            <div className="form">
                <input
                    type="text"
                    name="name"
                    placeholder="Item Name"
                    value={newItem.name}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="price"
                    placeholder="Item Price"
                    value={newItem.price}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="description"
                    placeholder="Item Description"
                    value={newItem.description}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="number"
                    name="rating"
                    placeholder="Item Rating"
                    value={newItem.rating}
                    onChange={handleInputChange}
                    required
                />
                <input
                    type="text"
                    name="image"
                    placeholder="Image URL (optional)"
                    value={newItem.image}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="uber_link"
                    placeholder="Uber Eats Link (optional)"
                    value={newItem.uber_link}
                    onChange={handleInputChange}
                />
                <input
                    type="text"
                    name="deliveroo_link"
                    placeholder="Deliveroo Link (optional)"
                    value={newItem.deliveroo_link}
                    onChange={handleInputChange}
                />
                <select
                    name="category"
                    value={newItem.category}
                    onChange={handleInputChange}
                    required
                >
                    <option value="">Select Category</option>
                    <option value="TEX MEX">Tex Mex</option>
                    <option value="TACOS">Tacos</option>
                    <option value="SANDWISH SPECIAL">Special Sandwich</option>
                    <option value="SANDICH">Sandwich</option>
                    <option value="BURGERS">Burgers</option>
                    <option value="ASSITTES">Plates</option>
                    <option value="KIDSMENU">Kids Menus</option>
                    <option value="PANINI">Panini</option>
                    <option value="SALADES">Salads</option>
                    <option value="DESSERTS">Desserts</option>
                    <option value="BOISSONS">Drinks</option>
                </select>
                <button onClick={handleAddItem}>{editIndex !== null ? 'Update Item' : 'Add Item'}</button>
            </div>
            <div className="admin-menu-list">
                {menuItems.map((item, index) => (
                    <div key={index} className="admin-menu-item">
                        <img src={item.image} alt={item.name} />
                        <div className="admin-item-price">{item.price}</div>
                        <div className="admin-item-category">{item.category}</div>
                        <div className="admin-item-info">
                            <h3>{item.name}</h3>
                            <p>{item.description}</p>
                            <p>{item.rating}</p>
                            <button className="edit-button" onClick={() => handleEditItem(index)}>Edit</button>
                            <button className="delete-button" onClick={() => handleDeleteItem(index)}>Delete</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AdminDashboard;