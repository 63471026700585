import React from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.css';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="contact-info">
                    <h3>{t('Contact Us')}</h3>
                    <p><i className="fas fa-phone"></i> <a href="tel:+33561262454">+33561262454</a></p>
                    <p><i className="fas fa-envelope"></i> <a href="mailto:heavenkitchen2024@gmail.com">heavenkitchen2024@gmail.com</a></p>
                    <p><i className="fas fa-map-marker-alt"></i> <a href="https://maps.app.goo.gl/xiXTPTo9GMQYj2ec8" target="_blank" rel="noopener noreferrer">36 Bd Victor Hugo, 31770 Colomiers, France</a></p>
                </div>
                <div className="opening-hours">
                    <h3>{t('Opening Hours')}</h3>
                    <p>{t('Monday to Friday')}: 7:30 AM - 11:00 PM</p>
                    <p>{t('Saturday to Sunday')}: 11:00 AM - 11:30 PM</p>
                </div>
                <div className="social-icons">
                    <h3>{t('Follow Us')}</h3>
                    <a href="https://web.facebook.com/people/The-Nice-Time/61571505110912/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                    <a href="https://www.instagram.com/thenicetime31770/?igsh=MXVmangwcmx2MmxmMw%3D%3D#" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                    <a href="https://www.tiktok.com/@the.nice.time?_t=ZN-8tMwHevaRfC&_r=1" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="footer-bottom-left">
                    <img src={`${process.env.PUBLIC_URL}/icon.svg`} alt="Nice Time Icon" className="footer-icon" />
                    <p>&copy; 2025 THENICETIME. {t('All rights reserved')}.</p>
                </div>
                <div className="payment-systems">
                    <div className="payment-icons">
                        <div className="payment-icon">
                            <img src={`${process.env.PUBLIC_URL}/visa.png`} alt="Visa" className="payment-icon-img" />
                            <span>Visa</span>
                        </div>
                        <div className="payment-icon">
                            <img src={`${process.env.PUBLIC_URL}/mastercard.png`} alt="MasterCard" className="payment-icon-img" />
                            <span>MasterCard</span>
                        </div>
                        <div className="payment-icon">
                            <img src={`${process.env.PUBLIC_URL}/ticket.png`} alt="Ticket Restaurant Eden" className="payment-icon-img" />
                            <span>Ticket Restaurant Eden</span>
                        </div>
                        <div className="payment-icon">
                            <img src={`${process.env.PUBLIC_URL}/cheque.png`} alt="Chèque Déjeuner" className="payment-icon-img" />
                            <span>Chèque Déjeuner</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;