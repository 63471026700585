import React, { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Hero.css';
import './Popup.css';

const BASE_URL = 'https://thenicelive.com.ipetmypets.com';

const Hero = () => {
    const { t } = useTranslation();
    const [randomItems, setRandomItems] = useState([]);

    useEffect(() => {
        AOS.init({ duration: 1000 });
        const fetchMenuItems = async () => {
            try {
                const response = await fetch(`${BASE_URL}/menu-items`);
                const data = await response.json();
                const shuffledItems = data.sort(() => 0.5 - Math.random());
                setRandomItems(shuffledItems.slice(0, 4)); // Get 4 random items
              
            } catch (error) {
                console.error('Error fetching menu items:', error);
            }
        };

        fetchMenuItems();
    }, []);
    const [popupItem, setPopupItem] = useState(null); 
    const handleItemClick = (item) => {
        setPopupItem(item); // Set the clicked item to show in the popup
    };
    
    const closePopup = () => {
        setPopupItem(null); // Close the item popup
    };


    return (
        <section id="hero-section" className="hero">
            <div className="hero-content">
                <div className="hero-left" data-aos="fade-right">
                    <h1>{t('Welcome to')}</h1>
                    <h2>{t('THENICETIME')}</h2>
                    <p>{t('Discover the taste of excellence')}</p>
                    <Link to="menu-section" smooth={true} duration={500}>
                        <button className="cta-button" data-aos="fade-up" data-aos-delay="200">
                            {t('Explore Menu')}
                        </button>
                    </Link>
                </div>
                <div className="hero-right" data-aos="fade-left">
                    <div className="image-grid">
                        {randomItems.map((item, index) => (
                            <div key={index} className="hero-item">
                                <img src={item.image} alt={item.name} className="hero-image"onClick={() => handleItemClick(item)} />
                                <div className="hero-overlay">
                                    <h2>{item.name}</h2>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                
            </div>
            {popupItem && (

<div className="popup-tab">
<div className="popup-content">
   
    <div className="popup-image">
        <img src={popupItem.image} alt={popupItem.name} />
    </div>
    <span className="popup-close" onClick={closePopup}>&times;</span>
    <div className="popup-title">
    
        <h3>{popupItem.name}</h3>  
    </div>
    <span className="popup-price">€{popupItem.price}</span>
    
</div>
</div>
            )  
                }
            <div className="hero-scroll-indicator" data-aos="fade-up" data-aos-delay="600">
                <span>{t('Scroll Down')}</span>
                <i className="fas fa-chevron-down"></i>
            </div>
        </section>
    );
};

export default Hero;