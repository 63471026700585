
import Hero from './Hero';
import Menu from './Menu';
import Footer from './Footer';
import Texmex from './Texmex';
import Header from './Header';
import './Home.css';

const Home = ({ menuItems }) => {
  

    return (
        <>
            <Header/>
            <section id="hero-section">
                <Hero />
            </section>
            <section id="texmex-section">
                <Texmex/>
            </section>
            <section id="menu-section">
                <Menu menuItems={menuItems} />
            </section>
            <section id="footer-section">
                <Footer />
            </section>
        </>
    );
};
export default Home;